import ExpandMore from "@mui/icons-material/ExpandMore";
import { Accordion, AccordionDetails, AccordionSummary, Grid, Stack, styled, Typography } from "@mui/material";
import { grey } from "@mui/material/colors";
import { AclPermissions, GroupAclDto, Language, mergeCamelized, SimplePermissionsDto, UserAclDto, UserDto, UserStatus } from "api-shared";
import { cloneDeep, uniqBy } from "lodash";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useGroups } from "../../domain/group";
import { isActiveUser, useAllUsers, useCurrentUser } from "../../domain/users";
import { useAlphanumericStringSort } from "../../hooks/useAlphanumericStringSort";
import { useDebounce } from "../../hooks/useDebounce";
import { useLanguage } from "../../hooks/useLanguage.ts";
import { trackEvent } from "../../infrastructure/tracking";
import { getFullGroupPath } from "../../lib/groups.ts";
import { translateFromProperty } from "../../lib/translate.ts";
import { renderPath } from "../../lib/tree.ts";
import { translationKeys } from "../../translations/main-translations";
import Alert from "../Alert";
import ActionItemDialog from "../dialogues/ActionItemDialog";
import DialogButton from "../dialogues/DialogButton";
import InfoIcon from "../icons/InfoIcon";
import LoadingAnimation from "../loading/LoadingAnimation";
import UserEntryWithPopup from "../user/UserEntryWithPopup";
import GroupItem from "./GroupItem";
import InviteSearchInput from "./InviteSearchInput";
import UserItem from "./UserItem";

const ScrollContainer = styled(Stack)({
    flexGrow: 1,
    flexBasis: 0,
    minHeight: 0,
    overflowX: "auto",
});

const ButtonBorder = styled(Stack)(({ theme }) => ({
    padding: theme.spacing(0.5, 0.5, 0.5, 0.5),
    backgroundColor: grey[200],
    borderRadius: theme.shape.borderRadius,
}));

const LeftStack = styled(Stack)(({ theme }) => ({
    borderRight: `1px solid ${theme.palette.divider}`,
    minHeight: theme.spacing(80),
    flexGrow: 1,
    flexBasis: 0,
    paddingRight: 0,
}));

const RightStack = styled(Stack)(({ theme }) => ({
    minHeight: theme.spacing(80),
    flexGrow: 1,
    flexBasis: 0,
}));

const TabStyleButton = styled(DialogButton, { shouldForwardProp: (prop) => prop !== "isActive" })<{ isActive?: boolean }>(
    ({ theme, isActive }) => ({
        borderColor: theme.palette.defaultDark.main,
        color: isActive ? theme.palette.text.primary : theme.palette.text.secondary,
        backgroundColor: isActive ? theme.palette.background.paper : grey[200],
        width: "50%",
        ":hover": {
            color: isActive ? "none" : theme.palette.text.primary,
            backgroundColor: isActive ? theme.palette.background.paper : grey[200],
        },
    }),
);

const StyledAccordion = styled(Accordion)({
    boxShadow: "none",
    border: "none",
    "::before": {
        // Remove seperator
        height: 0,
    },
});

const StyledAccordionSummary = styled(AccordionSummary)(({ theme }) => ({
    padding: theme.spacing(0),
    flexDirection: "row-reverse",
    "& .MuiAccordionSummary-expandIconWrapper": {
        transform: "rotate(-90deg)",
    },
    "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
        transform: "rotate(0deg)",
    },
}));

const StyledInfoIcon = styled(InfoIcon)(({ theme }) => ({
    marginTop: theme.spacing(0.25),
    marginLeft: theme.spacing(1),
    color: theme.palette.action.active,
}));

enum GroupTabs {
    Groups = "groups",
    Users = "users",
}

interface IUserGroupPermissionsDialogProps {
    open?: boolean;
    onClose?: () => void;
    permissions: SimplePermissionsDto;
    entityId: number;
    ownerId?: number;
    allowUpdatePermission?: boolean;
    readonly: boolean;
    showInvite?: boolean;

    filterAddableUsers?: (user: UserDto) => boolean;
    addGroup: (entityId: number, groupId: number, permission: AclPermissions) => void;
    addUser: (entityId: number, userId: number, permission: AclPermissions) => void;
    deleteGroup: (entityId: number, groupAclId: number) => void;
    deleteUser: (entityId: number, userAclId: number) => void;
    onInviteClick?: (email: string) => void;
}

export const UserGroupPermissionsDialog = ({
    open = false,
    onClose = () => void 0,
    permissions,
    entityId,
    ownerId,
    allowUpdatePermission = false,
    readonly,
    showInvite = false,
    filterAddableUsers = () => true,
    addGroup,
    addUser,
    deleteGroup,
    deleteUser,
    onInviteClick = () => void 0,
}: IUserGroupPermissionsDialogProps) => {
    const { t } = useTranslation();
    const language = useLanguage();
    const { compare } = useAlphanumericStringSort();

    const currentUser = useCurrentUser();
    const users = useAllUsers();
    const activeUsers = users.filter((user) => isActiveUser(user));
    const groups = useGroups();

    const [activeTab, setActiveTab] = useState(GroupTabs.Groups);
    const [expanded, setExpanded] = useState<boolean>(false);

    const [filter, setFilter] = useState("");
    const filterValue = useDebounce(filter);

    if (!groups.isSuccess || currentUser === null) {
        return <LoadingAnimation />;
    }

    const handleTabChange = (tab: GroupTabs) => {
        if (tab !== activeTab) {
            const action = tab === GroupTabs.Users ? "Switched To User Tab" : "Switched To Group Tab";
            trackEvent({ category: "Access Dialog", action });
        }

        setActiveTab(tab);
    };

    const groupNameById = (groupId: number) => {
        const currentGroup = groups.data?.find((group) => group.id === groupId);
        if (currentGroup === undefined || groups.data === undefined) {
            return "Unknown group";
        }

        return renderPath(getFullGroupPath(groups.data, currentGroup), mergeCamelized("name", language));
    };

    const groupDescriptionById = (groupId: number) => {
        const group = groups.data?.find((group) => group.id === groupId);
        return group ? translateFromProperty(group, "description", language) : "";
    };

    const enrichedGroupAcls = cloneDeep(permissions.groupAcls)
        .map((acl) => ({
            uid: `${acl.permission}.${acl.groupId}`,
            label: groupNameById(acl.groupId),
            description: groupDescriptionById(acl.groupId),
            ...acl,
        }))
        .toSorted((a, b) => compare(a.label, b.label));
    const enrichedUserAcls = cloneDeep(permissions.userAcls)
        .map((acl) => ({ uid: `${acl.permission}.${acl.userId}`, user: users.find((u) => u.id === acl.userId), ...acl }))
        .toSorted((a, b) => compare(a.user?.displayname ?? "", b.user?.displayname ?? ""));

    const addableUsers = activeUsers
        .filter(filterAddableUsers)
        .filter((u) => u.id !== currentUser.id)
        .filter((u) => !enrichedUserAcls.some((ua) => ua.userId === u.id))
        .filter(
            (u) =>
                (u.displayname !== null && u.displayname.toLowerCase().includes(filterValue.toLowerCase())) ||
                (u.email !== null && u.email?.toLowerCase().includes(filterValue.toLowerCase())),
        )
        .toSorted((a, b) => compare(a.displayname ?? "", b.displayname ?? ""));

    const addableGroups = groups.data
        ?.filter((g) => !enrichedGroupAcls.some((ga) => ga.groupId === g.id) && g.isVisible)
        .filter(
            (g) =>
                (language === Language.EN && g.nameEn.toLowerCase().includes(filterValue.toLowerCase())) ||
                (language === Language.DE && g.nameDe.toLowerCase().includes(filterValue.toLowerCase())),
        )
        .map((g) => ({ ...g, label: groupNameById(g.id) }))
        .toSorted((a, b) => compare(a.label, b.label));

    const isSpecialPermission = (acl: Omit<UserAclDto, "rule"> | Omit<GroupAclDto, "rule">) =>
        acl.simpleEntityId === null || !(acl.permission === AclPermissions.Read || AclPermissions.Update);

    const handleClose = () => {
        setFilter("");
        setActiveTab(GroupTabs.Groups);
        onClose();
    };

    // Only show the "higher" rule for groups
    const groupPermissionsWrite = enrichedGroupAcls
        .filter((acl) => !isSpecialPermission(acl))
        .filter((acl) => acl.permission === AclPermissions.Update);
    const groupPermissionsRead = enrichedGroupAcls
        .filter((acl) => !isSpecialPermission(acl))
        .filter(
            // Remove all simple update rules
            (acl) =>
                acl.permission === AclPermissions.Read &&
                !groupPermissionsWrite.some(
                    (g) => g.groupId === acl.groupId && g.simpleEntityId === acl.simpleEntityId && g.permission === AclPermissions.Update,
                ),
        );
    const groupPermissionsWriteIds = new Set(groupPermissionsWrite.map((acl) => acl.groupId));
    const groupPermissionsReadIds = new Set(groupPermissionsRead.map((acl) => acl.groupId));
    const groupPermissionsOther = enrichedGroupAcls.filter(
        // Only "special" permissions left here
        (acl) => !groupPermissionsReadIds.has(acl.groupId) && !groupPermissionsWriteIds.has(acl.groupId),
    );
    const groupPermissionsOtherWriteIds = new Set(
        groupPermissionsOther.filter((acl) => acl.permission === AclPermissions.Update).map((acl) => acl.groupId),
    );

    // Only show the "higher" rule for users
    const userPermissionsWrite = enrichedUserAcls
        .filter((acl) => !isSpecialPermission(acl))
        .filter((acl) => acl.permission === AclPermissions.Update);
    const userPermissionsRead = enrichedUserAcls
        .filter((acl) => !isSpecialPermission(acl))
        .filter(
            // Remove all simple update rules
            (acl) =>
                acl.permission === AclPermissions.Read &&
                !userPermissionsWrite.some(
                    (u) => u.userId === acl.userId && u.simpleEntityId === acl.simpleEntityId && u.permission === AclPermissions.Update,
                ),
        );
    const userPermissionsWriteIds = new Set(userPermissionsWrite.map((acl) => acl.userId));
    const userPermissionsReadIds = new Set(userPermissionsRead.map((acl) => acl.userId));
    const userPermissionsOther = enrichedUserAcls.filter(
        // Only "special" permissions left here
        (acl) => !userPermissionsReadIds.has(acl.userId) && !userPermissionsWriteIds.has(acl.userId),
    );
    const userPermissionsOtherWriteIds = new Set(
        userPermissionsOther.filter((acl) => acl.permission === AclPermissions.Update).map((acl) => acl.userId),
    );

    const deleteGroupCombined = (entityId: number, aclId: number) => {
        // Delete the read permission if the write permission should get removed
        const acl = enrichedGroupAcls.find((acl) => acl.id === aclId);
        if (acl?.permission === AclPermissions.Update) {
            const readAcl = enrichedGroupAcls.find(
                (g) => g.groupId === acl.groupId && g.simpleEntityId === acl.simpleEntityId && g.permission === AclPermissions.Read,
            );
            if (readAcl) {
                deleteGroup(entityId, readAcl.id);
            }
        }

        deleteGroup(entityId, aclId);
    };

    const deleteUserCombined = (entityId: number, aclId: number) => {
        // Delete the read permission if the write permission should get removed
        const acl = enrichedUserAcls.find((acl) => acl.id === aclId);
        if (acl?.permission === AclPermissions.Update) {
            const readAcl = enrichedUserAcls.find(
                (u) => u.userId === acl.userId && u.simpleEntityId === acl.simpleEntityId && u.permission === AclPermissions.Read,
            );
            if (readAcl) {
                deleteUser(entityId, readAcl.id);
            }
        }

        deleteUser(entityId, aclId);
    };

    const owner = users.find((u) => u.id === ownerId);

    return (
        <ActionItemDialog
            open={open}
            title={t("Visibility and access")}
            onClose={handleClose}
            translate={t}
            disableContentPadding
            maxWidth={readonly ? "sm" : "lg"}
            secondary={translationKeys.VDLANG_CLOSE}
        >
            <Stack direction="row">
                <LeftStack px={3} py={2}>
                    <ScrollContainer pr={2}>
                        {readonly && owner !== undefined && owner.status !== UserStatus.STATUS_DELETED ? (
                            <Alert severity="info" dense sx={{ mb: 1 }}>
                                {t(translationKeys.VDLANG_PERMISSION_DIALOG_ONLY_PERSON_CAN_CHANGE, {
                                    person: owner.displayname,
                                })}
                            </Alert>
                        ) : null}
                        <Typography fontWeight="medium" color="GrayText" variant="overline">
                            {t(translationKeys.VDLANG_MEASURE_ACCESS_CONTROL_WHO_HAS_ACCESS)}
                        </Typography>
                        <Stack gap={1}>
                            <Stack key={entityId} direction="row" alignItems="center" py={0.5} gap={1}>
                                <UserEntryWithPopup avatarProps={{ size: 40 }} user={owner} disableGutters />
                                <Typography>{t(translationKeys.VDLANG_ENTITY_OWNER)}</Typography>
                            </Stack>

                            {groupPermissionsWrite.map((acl) => (
                                <GroupItem
                                    key={acl.id}
                                    groupId={acl.groupId}
                                    groups={groups.data}
                                    buttonLabel={t(`${translationKeys.VDLANG_ACL_PERMISSIONS}.${acl.permission}`)}
                                    onClick={(permission) =>
                                        permission === null
                                            ? deleteGroupCombined(entityId, acl.id)
                                            : addGroup(entityId, acl.groupId, permission)
                                    }
                                    readonly={readonly}
                                    disabled={readonly}
                                    currentPermission={acl.permission}
                                    allowUpdate={!isSpecialPermission(acl) && !readonly && allowUpdatePermission}
                                    allowRemove={!isSpecialPermission(acl) && !readonly}
                                />
                            ))}
                            {groupPermissionsRead.map((acl) => (
                                <GroupItem
                                    key={acl.id}
                                    groupId={acl.groupId}
                                    groups={groups.data}
                                    buttonLabel={t(`${translationKeys.VDLANG_ACL_PERMISSIONS}.${acl.permission}`)}
                                    onClick={(permission) =>
                                        permission === null
                                            ? deleteGroupCombined(entityId, acl.id)
                                            : addGroup(entityId, acl.groupId, permission)
                                    }
                                    readonly={readonly}
                                    disabled={readonly}
                                    currentPermission={acl.permission}
                                    allowUpdate={!isSpecialPermission(acl) && !readonly && allowUpdatePermission}
                                    allowRemove={!isSpecialPermission(acl) && !readonly}
                                />
                            ))}

                            {userPermissionsWrite
                                .filter((u) => u.userId !== ownerId)
                                .map((acl) => (
                                    <UserItem
                                        key={acl.id}
                                        user={acl.user}
                                        readonly={readonly}
                                        buttonLabel={t(`${translationKeys.VDLANG_ACL_PERMISSIONS}.${acl.permission}`)}
                                        onClick={(permission) =>
                                            permission === null
                                                ? deleteUserCombined(entityId, acl.id)
                                                : addUser(entityId, acl.userId, permission)
                                        }
                                        disabled={isSpecialPermission(acl) || readonly || acl.userId === currentUser.id}
                                        currentPermission={acl.permission}
                                        allowUpdate={!isSpecialPermission(acl) && !readonly && allowUpdatePermission}
                                        allowRemove={!isSpecialPermission(acl) && !readonly}
                                    />
                                ))}
                            {userPermissionsRead
                                .filter((u) => u.userId !== ownerId)
                                .map((acl) => (
                                    <UserItem
                                        key={acl.id}
                                        user={acl.user}
                                        readonly={readonly}
                                        buttonLabel={t(`${translationKeys.VDLANG_ACL_PERMISSIONS}.${acl.permission}`)}
                                        onClick={(permission) =>
                                            permission === null
                                                ? deleteUserCombined(entityId, acl.id)
                                                : addUser(entityId, acl.userId, permission)
                                        }
                                        disabled={isSpecialPermission(acl) || readonly || acl.userId === currentUser.id}
                                        currentPermission={acl.permission}
                                        allowUpdate={!isSpecialPermission(acl) && !readonly && allowUpdatePermission}
                                        allowRemove={!isSpecialPermission(acl) && !readonly}
                                    />
                                ))}
                            {groupPermissionsOther.length > 0 || userPermissionsOther.length > 0 ? (
                                <StyledAccordion key={1} onChange={(e, exp) => setExpanded(exp)}>
                                    <StyledAccordionSummary expandIcon={<ExpandMore />}>
                                        <Typography sx={{ ml: 0.5 }}>{t(translationKeys.VDLANG_MEASURE_PRIVILEDGED_ACCESS)}</Typography>
                                        <StyledInfoIcon title={t(translationKeys.VDLANG_MEASURE_VISIBILITY_RESTRICTED_HINT)} />
                                    </StyledAccordionSummary>
                                    <AccordionDetails sx={{ pl: 0 }}>
                                        <Grid item xs={12}>
                                            {expanded ? (
                                                <>
                                                    {uniqBy(groupPermissionsOther, "uid").map((acl) =>
                                                        acl.permission === AclPermissions.Read &&
                                                        groupPermissionsOtherWriteIds.has(acl.groupId) ? null : (
                                                            <GroupItem
                                                                key={acl.id}
                                                                groupId={acl.groupId}
                                                                groups={groups.data}
                                                                buttonLabel={t(
                                                                    `${translationKeys.VDLANG_ACL_PERMISSIONS}.${acl.permission}`,
                                                                )}
                                                                onClick={(permission) =>
                                                                    permission === null
                                                                        ? deleteGroupCombined(entityId, acl.id)
                                                                        : addGroup(entityId, acl.groupId, permission)
                                                                }
                                                                readonly={readonly}
                                                                disabled={true}
                                                                currentPermission={acl.permission}
                                                            />
                                                        ),
                                                    )}

                                                    {uniqBy(userPermissionsOther, "uid")
                                                        .filter((u) => u.userId !== ownerId)
                                                        .map((acl) =>
                                                            acl.permission === AclPermissions.Read &&
                                                            userPermissionsOtherWriteIds.has(acl.userId) ? null : (
                                                                <UserItem
                                                                    key={acl.id}
                                                                    user={acl.user}
                                                                    readonly={readonly}
                                                                    buttonLabel={t(
                                                                        `${translationKeys.VDLANG_ACL_PERMISSIONS}.${acl.permission}`,
                                                                    )}
                                                                    onClick={(permission) =>
                                                                        permission === null
                                                                            ? deleteUserCombined(entityId, acl.id)
                                                                            : addUser(entityId, acl.userId, permission)
                                                                    }
                                                                    disabled={true}
                                                                    currentPermission={acl.permission}
                                                                />
                                                            ),
                                                        )}
                                                </>
                                            ) : null}
                                        </Grid>
                                    </AccordionDetails>
                                </StyledAccordion>
                            ) : null}
                        </Stack>
                    </ScrollContainer>
                </LeftStack>
                {!readonly ? (
                    <RightStack px={3} py={2} pr={0}>
                        <ScrollContainer pr={2}>
                            <Typography fontWeight="medium" color="GrayText" variant="overline">
                                {t(translationKeys.VDLANG_MEASURE_ACCESS_CONTROL_ADD_MORE_USERS_GROUPS)}
                            </Typography>
                            <Stack gap={1}>
                                <ButtonBorder direction="row" alignItems="space-evenly">
                                    <TabStyleButton
                                        onClick={() => handleTabChange(GroupTabs.Groups)}
                                        name={t(translationKeys.VDLANG_ADMIN_GROUPS_GROUPS)}
                                        variant={activeTab === GroupTabs.Groups ? "contained" : "text"}
                                        size="small"
                                        isActive={activeTab === GroupTabs.Groups}
                                    />
                                    <TabStyleButton
                                        onClick={() => handleTabChange(GroupTabs.Users)}
                                        variant={activeTab === GroupTabs.Users ? "contained" : "text"}
                                        name={t(translationKeys.VDLANG_ADMIN_GROUPS_USERS)}
                                        size="small"
                                        isActive={activeTab === GroupTabs.Users}
                                    />
                                </ButtonBorder>
                            </Stack>
                            <InviteSearchInput
                                searchKey={filter}
                                onChange={setFilter}
                                showInvite={showInvite}
                                onInviteClick={onInviteClick}
                                fullWidth={false}
                                sx={{ pt: 2, pb: 2 }}
                            />
                            <Stack gap={1}>
                                {activeTab === GroupTabs.Groups && addableGroups.length > 0
                                    ? addableGroups.map((group) => (
                                          <GroupItem
                                              key={group.id}
                                              groupId={group.id}
                                              groups={groups.data}
                                              buttonLabel={t("Add")}
                                              onClick={(permission) =>
                                                  permission != null ? addGroup(entityId, group.id, permission) : null
                                              }
                                              allowUpdate={allowUpdatePermission}
                                              readonly={readonly}
                                          />
                                      ))
                                    : null}
                                {activeTab === GroupTabs.Groups && addableGroups.length === 0 ? (
                                    <Typography color="GrayText">{t(translationKeys.VDLANG_PERMISSION_DIALOG_NO_GROUPS_FOUND)}</Typography>
                                ) : null}
                                {activeTab === GroupTabs.Users && addableUsers.length > 0
                                    ? addableUsers.map((user) => (
                                          <UserItem
                                              key={user.id}
                                              user={user}
                                              buttonLabel={t("Add")}
                                              onClick={(permission) => (permission != null ? addUser(entityId, user.id, permission) : null)}
                                              allowUpdate={allowUpdatePermission}
                                              readonly={readonly}
                                          />
                                      ))
                                    : null}
                                {activeTab === GroupTabs.Users && addableUsers.length === 0 ? (
                                    <Typography color="GrayText">{t(translationKeys.VDLANG_PERMISSION_DIALOG_NO_USERS_FOUND)}</Typography>
                                ) : null}
                            </Stack>
                        </ScrollContainer>
                    </RightStack>
                ) : null}
            </Stack>
        </ActionItemDialog>
    );
};
