import { Button, styled } from "@mui/material";

const DefaultButtonDark = styled(Button)(({ theme }) => ({
    borderColor: theme.palette.defaultDark.main,
    color: theme.palette.text.primary,
    ":hover": {
        borderColor: theme.palette.defaultDark.main,
    },
}));

export default DefaultButtonDark; // https://github.com/mui/material-ui/issues/15759#issuecomment-984553630
