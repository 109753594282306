import { Stack } from "@mui/material";
import { AclPermissions, UserDto } from "api-shared";
import UserEntryWithPopup from "../user/UserEntryWithPopup";
import PermissionsMenu from "./PermissionsMenu";

interface UserItemProps {
    currentPermission?: AclPermissions;
    onClick: (permission: AclPermissions | null) => void;
    user?: UserDto;
    readonly: boolean;
    buttonLabel: string;
    disabled?: boolean;
    allowUpdate?: boolean;
    allowRemove?: boolean;
}

export const UserItem = ({
    currentPermission,
    onClick,
    user,
    buttonLabel,
    disabled,
    allowUpdate,
    allowRemove,
    readonly,
}: UserItemProps) => {
    return (
        <Stack direction="row" alignItems="center" gap={1}>
            <UserEntryWithPopup avatarProps={{ size: 40 }} user={user} disableGutters />
            <PermissionsMenu
                currentPermission={currentPermission}
                allowUpdate={allowUpdate}
                allowRemove={allowRemove}
                disabled={disabled}
                buttonLabel={buttonLabel}
                updateValue={(permission) => onClick(permission)}
                readonly={readonly}
            />
        </Stack>
    );
};

export default UserItem;
