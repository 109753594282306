import { ListItemText, Menu, MenuItem, Tooltip, Typography, menuClasses, styled } from "@mui/material";
import { AclPermissions } from "api-shared";
import { useTranslation } from "react-i18next";
import useMenu from "../../hooks/useMenu";
import { translationKeys } from "../../translations/main-translations";
import DefaultButtonDark from "../DefaultButtonDark";

const OptionsMenuBase = styled(Menu)({
    [`& .${menuClasses.paper}`]: {
        maxWidth: 300,
    },
});

const OptionsMenuItem = styled(MenuItem)({
    whiteSpace: "inherit",
    height: "inherit",
});

const MenuOptionButton = styled(DefaultButtonDark)(({ theme }) => ({
    minWidth: theme.spacing(12),
}));

interface IPermissionsMenuProps {
    currentPermission?: AclPermissions;
    allowUpdate?: boolean;
    allowRemove?: boolean;
    disabled?: boolean;
    readonly?: boolean;
    buttonLabel: string;
    updateValue: (permission: AclPermissions | null) => void;
}

const PermissionsMenu = ({
    currentPermission,
    allowUpdate,
    allowRemove,
    disabled,
    readonly,
    buttonLabel,
    updateValue,
}: IPermissionsMenuProps) => {
    const { openMenu, menuProps, closeMenu } = useMenu();
    const { t: translate } = useTranslation();

    const removeOption = {
        id: null,
        name: translate(translationKeys.VDLANG_REMOVE),
        description: translate(translationKeys.VDLANG_PERMISSION_DIALOG_REMOVE_HINT),
    };
    const updateOption = {
        id: AclPermissions.Update,
        name: translate(`${translationKeys.VDLANG_ACL_PERMISSIONS}.${AclPermissions.Update}`),
        description: translate(translationKeys.VDLANG_PERMISSION_DIALOG_UPDATE_HINT),
    };
    const readOption = {
        id: AclPermissions.Read,
        name: translate(`${translationKeys.VDLANG_ACL_PERMISSIONS}.${AclPermissions.Read}`),
        description: translate(translationKeys.VDLANG_PERMISSION_DIALOG_READ_HINT),
    };

    const options: { id: AclPermissions | null; name: string; description: string }[] = [readOption];
    if (allowUpdate) {
        options.push(updateOption);
    }
    if (allowRemove) {
        options.push(removeOption);
    }

    const onSelect = (permission: AclPermissions | null) => {
        updateValue(permission);
        closeMenu();
    };

    const onlyOneOption = (currentPermission === AclPermissions.Read && removeOption && !allowUpdate) || options.length === 1;

    const updateValueForOneOption = () => {
        if (currentPermission === AclPermissions.Read) {
            onSelect(null);
        } else {
            onSelect(AclPermissions.Read);
        }
    };

    const isDelete = currentPermission === AclPermissions.Read && allowRemove;

    if (readonly && isDelete) {
        return null;
    }

    if (readonly && !isDelete) {
        return <Typography>{buttonLabel}</Typography>;
    }

    return (
        <>
            {!disabled && !onlyOneOption ? (
                <>
                    <OptionsMenuBase {...menuProps}>
                        {options.map((o) => (
                            <OptionsMenuItem key={o.id} onClick={() => onSelect(o.id)}>
                                <ListItemText
                                    primary={o.name}
                                    secondary={o.description}
                                    primaryTypographyProps={o.id === null ? { color: "error" } : {}}
                                    secondaryTypographyProps={o.id === null ? { color: "error" } : {}}
                                />
                            </OptionsMenuItem>
                        ))}
                    </OptionsMenuBase>
                    <MenuOptionButton size="small" onClick={openMenu}>
                        {buttonLabel}
                    </MenuOptionButton>
                </>
            ) : (
                <Tooltip title={disabled ? translate(translationKeys.VDLANG_ACL_DISABLED_OTHER) : ""}>
                    <span>
                        <MenuOptionButton size="small" variant="outlined" disabled={disabled} onClick={updateValueForOneOption}>
                            {isDelete ? translate("Delete") : buttonLabel}
                        </MenuOptionButton>
                    </span>
                </Tooltip>
            )}
        </>
    );
};
export default PermissionsMenu;
