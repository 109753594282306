import { Button, TextFieldProps } from "@mui/material";

import { AclNamespaces, AclPermissions, FeatureFlags } from "api-shared";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import isEmail from "validator/lib/isEmail";
import { useClientDomains, useClientHasFeature } from "../../domain/client";
import { useUserHasPermissionQuery } from "../../domain/permissions";
import { useSeatLimits } from "../../domain/seat-limit";
import { useAllUsers } from "../../domain/users";
import { translationKeys } from "../../translations/main-translations";
import Tooltip from "../Tooltip";
import SearchInput from "../input/SearchInput";

interface IInviteSearchInputProps extends Omit<TextFieldProps, "onChange" | "translate"> {
    onChange: (newSearchKey: string) => void;
    onReset?: () => void;
    onInviteClick?: (email: string) => void;
    searchKey: string;
    showInvite?: boolean;
}

const InviteSearchInput = React.forwardRef<HTMLDivElement, IInviteSearchInputProps>(
    ({ searchKey, onChange, onReset, onInviteClick, showInvite, InputProps, ...textFieldProps }, ref) => {
        const { t: translate } = useTranslation();
        const [filterKey, setFilterKey] = useState(searchKey);

        const hasContributorInviteFeature = useClientHasFeature(FeatureFlags.FEATURE_CONTRIBUTOR_INVITE_BY_USER);
        const hasResponsiblePermissionQuery = useUserHasPermissionQuery({
            namespace: AclNamespaces.Process,
            permission: AclPermissions.Responsible,
            fact: {},
        });

        const seatLimits = useSeatLimits();
        const clientDomains = useClientDomains();

        const isValidEmail = isEmail(filterKey);
        const isValidDomain = clientDomains.find(({ name }) => filterKey.split("@")[1] === name) !== undefined;

        const allUsers = useAllUsers();
        const isUserExisting = allUsers.find((user) => user.email === filterKey) !== undefined;

        const inviteButtonTooltipText = () => {
            let key = "";

            if (!isValidEmail) {
                key = translationKeys.VDLANG_INVITE_USER_INVALID_EMAIL;
            } else if (!isValidDomain) {
                key = translationKeys.VDLANG_INVITE_USER_INVALID_DOMAIN;
            } else if (isUserExisting) {
                key = translationKeys.VDLANG_INVITE_USER_ALREADY_EXISTS;
            } else if (!seatLimits.basic.hasOpenSeats) {
                key = translationKeys.VDLANG_ADMIN_BASIC_USER_SEATS_IN_USE_HINT;
            }

            return key != "" ? translate(key) : null;
        };

        const onChangeValue = (value: string) => {
            setFilterKey(value);
            onChange(value);
        };

        if (!hasResponsiblePermissionQuery.isSuccess) {
            return null;
        }

        const canUseContributorInvite = hasContributorInviteFeature && hasResponsiblePermissionQuery.data.hasPermission;

        return (
            <SearchInput
                translate={translate}
                placeholder={translate(translationKeys.VDLANG_ENTER_NAME_OR_EMAIL)}
                searchKey={searchKey}
                onChange={onChangeValue}
                onReset={onReset}
                endAdornment={
                    showInvite && canUseContributorInvite && filterKey !== "" && onInviteClick ? (
                        <Tooltip title={inviteButtonTooltipText()}>
                            <span>
                                <Button
                                    onClick={() => onInviteClick(filterKey)}
                                    disabled={isUserExisting || !isValidEmail || !isValidDomain || !seatLimits.basic.hasOpenSeats}
                                    size="small"
                                    variant="contained"
                                >
                                    {translate("invite")}
                                </Button>
                            </span>
                        </Tooltip>
                    ) : null
                }
                InputProps={InputProps}
                {...textFieldProps}
            />
        );
    },
);

export default InviteSearchInput;
