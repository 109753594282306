import { Button, Grid, styled, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import ProcessNotFoundIllustration from "../static/images/process_not_found.svg";
import { translationKeys } from "../translations/main-translations";

const Message = styled(Typography)(({ theme }) => ({
    padding: theme.spacing(0, 2),
}));

const Image = styled("img")(({ theme }) => ({
    padding: theme.spacing(5),
    maxWidth: "100%",
    maxHeigh: "100%",
}));

const BackButton = styled(Button)(({ theme }) => ({
    margin: theme.spacing(2),
}));

const FullHeightGrid = styled(Grid)(({ theme }) => ({
    height: "100%",
}));

interface IItemNotFound {
    toolTip: string;
    onClick?: React.MouseEventHandler<HTMLButtonElement>;
}

const ItemNotFound = ({ toolTip, onClick }: IItemNotFound) => {
    const { t: translate } = useTranslation();
    const navigate = useNavigate();
    const handleClick = () => navigate(-1);
    return (
        <FullHeightGrid container alignItems="center" justifyContent="center">
            <Grid item>
                <Grid container direction="column" alignItems="center">
                    <Image src={ProcessNotFoundIllustration} alt="" />
                    <Message align="center" variant="body1">
                        {translate(toolTip)}
                    </Message>
                    <BackButton onClick={onClick ?? handleClick} variant="contained">
                        {translate(translationKeys.VDLANG_BACK)}
                    </BackButton>
                </Grid>
            </Grid>
        </FullHeightGrid>
    );
};

export default ItemNotFound;
